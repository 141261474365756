.footer {
  color: #555;;
  background-color: #fff;
}

.carousel-item {
  height: 400px;
}

.carousel-item img {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  min-width: 100%;
}

